/* You can add global styles to this file, and also import other style files */
/* Webfont: Lato-Black */
@font-face {
  font-family: "LatoWebBlack";
  src: url("assets/fonts/Lato-Black.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-Black.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-Black.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-Black.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BlackItalic */
@font-face {
  font-family: "LatoWebBlack";
  src: url("assets/fonts/Lato-BlackItalic.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-BlackItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-BlackItalic.woff")
      format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-BlackItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */
@font-face {
  font-family: "LatoWebBold";
  src: url("assets/fonts/Lato-Bold.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-Bold.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-Bold.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BoldItalic */
@font-face {
  font-family: "LatoWebBold";
  src: url("assets/fonts/Lato-BoldItalic.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-BoldItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-BoldItalic.woff")
      format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-BoldItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Hairline */
@font-face {
  font-family: "LatoWebHairline";
  src: url("assets/fonts/Lato-Hairline.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-Hairline.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-Hairline.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-Hairline.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-Hairline.ttf")
      format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HairlineItalic */
@font-face {
  font-family: "LatoWebHairline";
  src: url("assets/fonts/Lato-HairlineItalic.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-HairlineItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-HairlineItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-HairlineItalic.woff")
      format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-HairlineItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Heavy */
@font-face {
  font-family: "LatoWebHeavy";
  src: url("assets/fonts/Lato-Heavy.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-Heavy.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-Heavy.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-Heavy.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-Heavy.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HeavyItalic */
@font-face {
  font-family: "LatoWebHeavy";
  src: url("assets/fonts/Lato-HeavyItalic.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-HeavyItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-HeavyItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-HeavyItalic.woff")
      format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-HeavyItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Italic */
@font-face {
  font-family: "LatoWeb";
  src: url("assets/fonts/Lato-Italic.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-Italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-Italic.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-Italic.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-Italic.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */
@font-face {
  font-family: "LatoWebLight";
  src: url("assets/fonts/Lato-Light.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-Light.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-Light.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-Light.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-LightItalic */
@font-face {
  font-family: "LatoWebLight";
  src: url("assets/fonts/Lato-LightItalic.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-LightItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-LightItalic.woff")
      format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-LightItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */
@font-face {
  font-family: "LatoWebMedium";
  src: url("assets/fonts/Lato-Medium.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-Medium.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-Medium.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-Medium.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-MediumItalic */
@font-face {
  font-family: "LatoWebMedium";
  src: url("assets/fonts/Lato-MediumItalic.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-MediumItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-MediumItalic.woff")
      format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-MediumItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */
@font-face {
  font-family: "LatoWeb";
  src: url("assets/fonts/Lato-Regular.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-Regular.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-Regular.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-Regular.ttf")
      format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */
@font-face {
  font-family: "LatoWebSemibold";
  src: url("assets/fonts/Lato-Semibold.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-Semibold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-Semibold.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-Semibold.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-Semibold.ttf")
      format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-SemiboldItalic */
@font-face {
  font-family: "LatoWebSemibold";
  src: url("assets/fonts/Lato-SemiboldItalic.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-SemiboldItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-SemiboldItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-SemiboldItalic.woff")
      format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-SemiboldItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Thin */
@font-face {
  font-family: "LatoWebThin";
  src: url("assets/fonts/Lato-Thin.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-Thin.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-Thin.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-Thin.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-ThinItalic */
@font-face {
  font-family: "LatoWebThin";
  src: url("assets/fonts/Lato-ThinItalic.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-ThinItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-ThinItalic.woff")
      format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-ThinItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

body {
  //  font-family: "LatoWeb";
}
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
.product-m {
  .owl-prev,
  .owl-next {
    opacity: 0;
    transform: scale(1);
    transition: all 0.5s ease;
    margin-top: -10px;
    border-radius: 100% !important;
    display: flex !important;
    align-items: center;
    justify-content: center;

    // background: rgba(255, 255, 255, 0.15) !important;
    i {
      font-size: 8px;
    }
  }

  .owl-next {
    right: 1px;
  }

  .owl-prev {
    left: 1px;
    z-index: 1;
  }

  &:hover {
    .owl-prev,
    .owl-next {
      opacity: 1;
      transform: scale(2.5);
      transition: all 0.5s ease;

      &:before {
        opacity: 1;
      }
    }

    .owl-next {
      right: 100px;
    }

    .owl-prev {
      left: 100px;
    }
  }
}

.blog-slider {
  .owl-prev,
  .owl-next {
    opacity: 0;
    transform: scale(1);
    transition: all 0.5s ease;
    margin-top: -10px;
    border-radius: 100% !important;
    display: flex !important;
    align-items: center;
    justify-content: center;

    // background: rgba(255, 255, 255, 0.15) !important;
    i {
      font-size: 8px;
    }
  }

  .owl-next {
    right: 1px;
  }

  .owl-prev {
    left: 1px;
    z-index: 1;
  }

  &:hover {
    .owl-prev,
    .owl-next {
      opacity: 1;
      transform: scale(2.5);
      transition: all 0.5s ease;

      &:before {
        opacity: 1;
      }
    }

    .owl-next {
      right: 100px;
    }

    .owl-prev {
      left: 100px;
    }
  }
}

.owl-prev.disabled {
  display: none !important;
}

.owl-next.disabled {
  display: none !important;
}

// .vertical .owl-stage .owl-item {
//   display: flex;
//   justify-content: space-between;
//   width: 100% !important;
//   top: calc(50% - 33px);
// }

// .vertical .owl-carousel {
//   transform: rotate(90deg);
//   width: 270px;
//   margin-top: 100px;
// }

// .vertical .owl-stage .owl-item {
//   transform: rotate(-90deg);
// }

.gray-color {
  color: #5a5d5c;
}
.gray-color:hover {
  color: #5a5d5c;
  text-decoration: underline;
}
.select_input select {
  padding: 15.5px 25px;
  margin-bottom: 30px;
  height: auto;
}
.color-variant {
  margin-bottom: 10px;
  img {
    height: 30px;
    width: 30px;
    cursor: pointer;

    display: inline-block;
    border-radius: 100%;
    margin-right: 5px;
    transition: all 0.1s ease;
    vertical-align: middle;

    &.active {
      border: 1px solid black;
      position: relative;
      &:after {
        content: "";
        background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
        top: 10px;
        right: 4px;
        height: 15px;
        width: 15px;
        background-size: 70%;
        background-repeat: no-repeat;
        position: absolute;
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.border-gray {
  border: 1px solid #dedede;
}
.width-available {
  width: -webkit-fill-available;
}
.color-dark {
  color: #222222;
}

.tab-titles-ship .nav-pills {
  border-bottom: 1px solid;
  padding-bottom: 8px;
  margin-bottom: 13px;
  margin-left: 15px;
  margin-right: 15px;
}
.tab-titles-ship .nav-item .nav-link.active {
  background-color: #5a5d5c !important;
  color: #fff;
}
.tab-titles-ship .nav-item {
  display: inline-block;
  width: 50%;
  text-align: center;
}
.tab-titles-ship .nav-item .nav-link {
  color: #5a5d5c;
  font-size: 16px;
  font-weight: bold;
}

////
/// start checkbox
///
/* This css is for normalizing styles. You can skip this. */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.new {
  padding: 50px;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input[type="checkbox"] {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group .check-lable {
  position: relative;
  cursor: pointer;
  display: flex;
}

.form-group .check-lable:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #5a5d5c;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  height: 18px;
  width: 18px;
}

.form-group input[type="checkbox"]:checked + .check-lable:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 4px;
  height: 12px;
  border: solid #5a5d5c;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
/// end checkbox
///
.color-dark {
  color: #1a1e1c;
}
@media (max-width: 577px) {
  .nav-menu-profile {
    display: none !important;
  }
}

header {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.mat-chip-list-wrapper {
  padding: 5px 10px 5px 10px;
  border: 1px solid #ced4da;
  margin: 0px !important;
  min-height: 50px;
}
.mat-standard-chip {
  border-radius: 6px;
}

.iti__country-list {
  display: grid;
}
.iti__hide {
  display: none !important;
}
.iti {
  width: 100%;
}
.col-form-label {
  color: #1a1e1c;
  font-weight: 500;
  margin-bottom: 0.5rem !important;
  font-size: 16px;
}
.iti--allow-dropdown input {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid #ced4da;
}
.invalid-feedback {
  display: unset;
  font-size: 13px;
}

body {
  font-size: 16px;
  // background: #f1f1f4;
  background: #ffffff;
}

input[type="date"] {
  display: block;
  position: relative;
  padding: 0.7rem 3.5rem 0.7rem 0.75rem;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #898989;
  font-size: 1rem;
  font-family: monospace;

  border: 1px solid #ced4da;
  background: white
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='22' viewBox='0 0 20 22'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23ced4da' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(1 1)'%3E%3Crect width='18' height='18' y='2' rx='2'/%3E%3Cpath d='M13 0L13 4M5 0L5 4M0 8L18 8'/%3E%3C/g%3E%3C/svg%3E")
    right 1rem center no-repeat;

  cursor: pointer;
}
input[type="date"]:focus {
  outline: none;
}

::-webkit-datetime-edit {
}
::-webkit-datetime-edit-fields-wrapper {
}
::-webkit-datetime-edit-month-field:hover,
::-webkit-datetime-edit-day-field:hover,
::-webkit-datetime-edit-year-field:hover {
}
::-webkit-datetime-edit-text {
  opacity: 0;
}
::-webkit-clear-button,
::-webkit-inner-spin-button {
  display: none;
}
::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 2.5rem;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;

  opacity: 0;
  cursor: pointer;

  color: rgba(0, 120, 250, 1);
  background: rgba(0, 120, 250, 1);
}

input[type="date"]:hover::-webkit-calendar-picker-indicator {
  opacity: 0.05;
}
input[type="date"]:hover::-webkit-calendar-picker-indicator:hover {
  opacity: 0.15;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #898989;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.heads {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
